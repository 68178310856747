.mega-nav {
  @apply hidden sm:block absolute top-full inset-x-0 z-30;

  /* Background of opened mega nav */
  &--bg {
    @apply hidden sm:block;

    &::before {
      @apply absolute top-full inset-x-0 z-10 h-px bg-pageBG;
      will-change: transform;
      transform-origin: 0 0;
      transform: scaleY(var(--h));
      transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      content: '';

      /* account for the header when styled as an overlay that hasn't scrolled */
      .is-overlay:not(.has-bg) & {
        @apply top-0;
      }
    }

    &::after {
      @apply absolute top-full inset-x-0 -mt-px z-20;
      transform: translate3d(0, var(--hpx), 0);
      transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
      content: '';

      /* account for the header when styled as an overlay that hasn't scrolled */
      .is-overlay:not(.has-bg) & {
        @apply top-0;
      }
    }
  }

  &--border {
    @apply absolute inset-x-0 top-full z-10 h-px;
  }

  &--backdrop {
    @apply fixed inset-0 z-0 bg-opacity-0 pointer-events-none opacity-0;
    transition: opacity 0.1s linear;

    &.is-active {
      @apply pointer-events-auto opacity-100;
    }
  }
}

/* Customize megadropdown navigation buttons: */
/* (you should mostly offload these to the general header links/button styles) */
.mega-toggle {
  &--icon {
    @apply inline-block mr-2 rounded-full;
    width: 4px;
    height: 4px;

    .is-open & {
      @apply bg-pageText;
      animation: pulse 2s infinite;
    }
  }
}

.mega-item {
  @apply absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden invisible pointer-events-none;
  transition: visibility 0.1s;

  &.is-active {
    @apply visible pointer-events-auto;
    transition-delay: 0s;
  }

  &--content {
    @apply grid grid-cols-2 relative px-6 py-4 border-b-2 border-pageBGDark;
    will-change: transform;
  }

  ul {
    @apply list-none;
    a,
    button {
      @apply inline-block leading-normal hover:opacity-40 transition-opacity duration-200;
    }
  }

  &--featured {
    @apply grid grid-cols-2 gap-4 max-w-2xl relative w-full border-l border-pageBGDark pl-8;

    &-title {
      @apply block absolute top-0 right-full w-2 text-xs uppercase tracking-widest font-semibold;

      span {
        @apply block overflow-hidden absolute top-1/2 left-0 whitespace-nowrap transform rotate-90;
        transform-origin: 0 0;
      }
    }
  }
}
