.collection-tiled-images {
  @apply px-5 py-24 mx-auto max-w-screen-xl;

  &--products {
    @apply flex flex-wrap -m-4 mx-auto;
  }

  &--product {
    @apply p-3 w-full sm:w-1/3;
  }

  &--content {
    @apply flex relative cursor-pointer text-white text-center;
  }

  &--details {
    @apply px-8 py-10 relative z-10 w-full bg-opacity-10 md:bg-opacity-25 bg-black md:opacity-0;
    aspect-ratio: 2/3;
    transition: opacity 0.45s linear;
  }

  .is-hover {
    @apply absolute inset-0 z-10 opacity-0;
    transition: opacity 0.45s linear;
  }
}

.collection-tiled-images--product:hover {
  .collection-tiled-images--details {
    @apply opacity-100;
  }

  .is-hover {
    @apply opacity-100;
  }
}
