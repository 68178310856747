.press-list {
  @apply relative p-4 sm:p-8;

  &--featured {
    @apply bg-white py-24;
  }
}

.press-text-list {
  @apply relative p-4 sm:p-8;

  &--outlets {
    @apply flex flex-wrap -mx-4 overflow-hidden;
  }

  &--press {
    @apply my-4 px-4 w-full overflow-hidden sm:w-1/2 md:w-1/3 lg:w-1/4;
  }
  &--name {
    @apply font-bold text-base;
  }
  &--address {
    @apply mt-2;
  }
  &--website {
    @apply mt-2 text-sm;
  }
  &--excerpt:not(:first-child) {
    @apply mt-8;
  }
  &--read-more {
    @apply text-xs;
    svg {
      @apply h-4;
    }
  }
  &--date,
  &--description {
    @apply text-sm;
  }
}

.press-list-featured {
  &--heading {
    @apply text-center text-black;
  }

  &--logos {
    @apply flex flex-wrap -mx-4 mt-12 overflow-hidden justify-center items-center;
  }

  &--press {
    @apply my-16 px-10 w-full overflow-hidden text-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5;
    max-width: 300px;
  }

  &--logo figure {
    @apply mx-auto;
  }

  &--logo {
    @apply m-1 block;
  }
}
