.btn {
  @apply clean-btn font-display inline-block relative bg-transparent px-5 py-2 text-center border text-xs;

  &.is-block {
    @apply block w-full;
  }

  &.is-loading {
    @apply cursor-wait !important;
  }

  &.is-disabled {
    @apply cursor-default pointer-events-none;
  }

  /* default: hover */
  &:not(.is-primary):not(.is-white):not(.is-disabled):not(.is-soldout) {
    &.is-active,
    &:hover {
      @apply bg-pageTheme border-pageTheme text-pageBG;
    }
  }

  /* default: inverted */
  &.is-inverted {
    @apply bg-pageTheme border-pageBG text-pageBG;

    &:not(.is-primary):not(.is-white):not(.is-disabled):not(.is-soldout) {
      &.is-active,
      &:hover {
        @apply bg-pageBG border-pageBG text-pageTheme;
      }
    }
  }

  /* default: disabled */
  &.is-disabled:not(.is-primary),
  &.is-soldout {
    @apply bg-pageBG opacity-20;
  }

  /* primary style button */
  &.is-primary {
    @apply bg-pageTheme border-pageTheme text-pageBG;

    /* primary: inverted */
    &.is-inverted {
      @apply bg-pageBG border-pageBG text-pageTheme;
    }
  }

  /* white style button */
  &.is-white {
    @apply bg-white border-white text-black;
  }

  /* text-based button */
  &.is-text {
    @apply px-0 bg-transparent border-transparent;

    &.is-disabled,
    &:not(.is-disabled):hover {
      @apply opacity-60;
    }
  }

  /* large sized button */
  &.is-large {
    @apply text-lg px-7;
  }
}
