.product {
  /* @apply border-b; */
  &--content {
    @apply relative grid sm:grid-cols-12;
  }

  &--gallery {
    @apply relative bg-pageBG sm:col-span-7;

    .carousel {
      @apply max-w-5xl mx-auto;
    }
  }

  &--details {
    @apply bg-pageBG flex flex-col p-5 md:p-24  sm:col-span-5;
  }

  &--specs-heading {
    @apply text-base mt-8 border-b;
  }

  &--specs-description {
    @apply mt-2;
  }

  &--info {
    @apply w-full max-w-xl sticky top-20;
  }

  &--desc {
    @apply mt-3;
  }

  &--header {
    @apply flex pb-2;
  }

  .price {
    @apply flex flex-col items-start ml-auto;

    &--discount {
      @apply mb-2 p-1 rounded-sm bg-pageText text-pageBG text-xxs leading-tight tracking-widest font-semibold uppercase;
    }
  }

  &--title {
    @apply pr-8 sm:pr-12 text-sm;
  }

  &--name {
    @apply m-0 text-2xl leading-snug;
  }

  &--variant {
    @apply flex items-center mb-2 text-sm leading-relaxed tracking-wider font-semibold uppercase;

    .label {
      @apply -my-1 transform scale-90;
    }
  }

  &--options {
    @apply mt-5;
  }

  &--actions {
    @apply sm:flex py-4 bg-pageBG mt-5;
    /* @apply sm:flex sticky bottom-0 mt-4 p-4 md:p-8 border-t bg-pageBG is-textured text-center; */

    button {
    }

    .counter {
      @apply hidden sm:inline-flex;
    }
  }

  &--actions-learn-more {
    @apply ml-3 py-4;
  }

  &--waitlist {
    @apply relative w-full mt-8;
  }

  &--waitlist-description {
    @apply text-xs text-left;
  }

  &--learn-more {
    @apply flex flex-row items-center relative mt-8;

    svg {
      @apply h-4;
    }
  }

  &--shared-info {
    @apply p-4 sm:p-8 max-w-screen-xl;
  }
}

.option {
  @apply flex mt-4 py-4 border-t;

  &--title {
    @apply mr-12 text-xs font-semibold uppercase tracking-wider;
  }

  &--values {
    @apply flex flex-wrap justify-end gap-2 gap-y-2 gap-x-2 ml-auto;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }

  &--swatch {
    @apply clean-btn p-0 bg-transparent;
  }
}
